<template>
  <v-container id="dashboard" fluid tag="section">
    <v-alert
      v-model="alert"
      outlined
      type="warning"
      prominent
      border="left"
      text
      color="orange"
      v-if="notifications.length > 0"
    >
      <v-list>
        <v-list-item v-for="(n, i) in notifications" :key="`item-${i}`">
          <v-list-item-content>
            <v-list-item-title v-text="n"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-alert>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="d-flex flex-column justify-space-between align-center">
      <v-img src="/img/matrice_logo_bco.jpg" cover></v-img>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <!--    
    <home-boxes-component :key="`${componentKey}-1`"></home-boxes-component>
    <home-graphs-component :key="`${componentKey}-1`"></home-graphs-component>
    <home-latest-grids-component  :key="`${componentKey}-1`"></home-latest-grids-component>
    -->
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: "DashboardDashboard",

  data() {
    return {
      width: 300,
      aspectRatio: 16 / 9,
      snackbar: false,
      visible: true,
      text: "Registro Insertado",
      color: "success",
      timeout: 4000,
      alert: true,
      notifications: {},
      componentKey: 0,
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },
  created() {
    //this.getAlertas();
    /*if(this.$store.state.role_id != "3"){
        this.getAlertas();
      }*/
  },
  mounted: function () {},
};
</script>
